body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top:15px;
}

.nav-link, body{
  color:	rgb(0,0,0)!important;
}


.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.filler{
  width: 100%;
  min-height: 300px;
  padding:50px;
  
}
.content-header{
  padding-left:50px;
  padding-top:20px;
}

.page-content{
  min-height: 475px;
}

@media (max-width: 991px){
  nav{
    top: 325px !important;
    width: 100%;
  }
  .navbar{
    flex-flow: row nowrap;
    position: absolute !important;
    
  }
  
  .first{
    margin-right: 0 !important;
  }

  .navbar-nav{
    flex-direction: row;
    margin-right: 0 !important;
  }

  .h2-section{
    margin-top:58px;
  }

  .first-link, .second-link{
    width:50%;
  }

  .second{
    -ms-flex-pack: none!important;
    justify-content: none!important;
  }

}


